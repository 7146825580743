.module-header__card {
  display: flex;
  padding: 10px;
  background: white;
  border-radius: 8px;
  border: 1px solid var(--gray-1);
  box-shadow: 0px 0px 10px 0px var(--gray-1);
  -webkit-box-shadow: 0px 0px 10px 0px var(--gray-1);
  -moz-box-shadow: 0px 0px 10px 0px var(--gray-1);
}

.module-header__card svg {
  width: 60px;
  height: 60px;
  margin: auto 20px;
  padding: 10px;
  border-radius: 12px;
  border: 3px solid var(--primary);
  color: var(--primary);
}

.module-header__card-title {
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 25px;
  font-weight: 500;
  padding-left: 10px;
  color: var(--primary);
}

.module-header__card-subtitle {
  padding-left: 10px;
  color: grey;
}

.module-header__card-action-cont {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

@media screen and ( max-width: 100px ) {
  
}