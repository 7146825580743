.topbar-container {
  position: sticky;
  top: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--white);
  box-shadow: 0px 0px 10px 0px var(--gray-1);
  -webkit-box-shadow: 0px 0px 10px 0px var(--gray-1);
  -moz-box-shadow: 0px 0px 10px 0px var(--gray-1);
  z-index: 999;

  .toggle-menu {
    margin-left: 30px;
    transition: all 500ms;
    outline: none;

    svg {
      color: var(--primary-light);
    }

    &:hover {
      background-color: var(--primary-hover);
    }
  }

  .toggle-menu-expand {
    margin-left: 200px;
    transition: all 500ms;
    outline: none;
    
    svg {
      color: var(--primary-light);
    }

    &:hover {
      background-color: var(--primary-hover);
    }
  }

  .dropdown-toggle {
    border: none;
    border-radius: 34px !important;
    background: var(--primary-light) !important;
    margin-right: 10px;
    padding: 6px;
    z-index: 1;
  }

  .dropdown-toggle:focus,
  .dropdown-toggle:hover {
    box-shadow: none;
    background: var(--primary) !important;
  }

  .dropdown-toggle img {
    width: 40px;
    height: 40px;
  }
  
  .logo-bars {
    margin-left: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .dropdown-item:hover {
    color: white;
    background-color: var(--primary-light);
  }
}