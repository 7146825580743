@import "src/stylesheets/button.scss";

.at-login__background {
  display: flex;
  height: 100vh;
  background: url('../../assets/login-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  // background-color: #F1F1F1;
}

.at-login__container {
  margin: auto;
  margin-right: 50px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #c9c9c9;
  background: white;

  img {
    display: flex;
    justify-content: center;
    margin: 20px 0px;
    width: 350px;
  }

  @media screen and ( max-width: 576px ) {
    width: 300px;
    margin-right: auto;

    img {
      width: 250px;
    }
  }
}

.at-login__title {
  width: 500px;
  margin: auto;
  font-size: 55px;
  text-align: center;
  margin-bottom: 30px;
}

.at-login__input{
  width: 300px;
  height: 50px;
  border-radius: 10px;
  background: #ffffffb0;
  border: 1px solid grey;
  font-weight: 500;
  font-size: 20px;
  padding-left: 20px;

  @media screen and ( max-width: 576px ) {
    font-size: 16px;
    width: 250px;
    height: 40px;
  }
}

.at-login__login-button {
  width: 180px;
  height: 50px;

  @media screen and ( max-width: 576px ) {
    width: 150px;
    height: 40px;
  }
}

.at-login__forget-password-button {
  border: none;
  background: none;
  color: #4FA7DF;
  font-size: 12px;
  text-decoration: underline;

  &:hover {
    color: #177ebe;
  }
}

.at-login__toggle {
  position: absolute;
  top: 10px;
  right: 10px;
}