.at-empty-state-cont {
  text-align: center;
  margin: 100px 0px;

  .title {
    font-size: 18px;
    font-weight: 600;
    margin: 0px;
  }

  .description {
    font-size: 14px;
    color: grey;
    margin: auto;
    min-width: 200px;
    max-width: 400px;
  }
}

.at-empty-state__icon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 10px auto;
  background: #e1e1e1;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 50px;
    height: 50px;
    color: grey;
  }
}